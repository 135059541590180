import React from 'react'
import Alert from 'react-bootstrap/Alert'

const url = (
  'https://ex-mco.auth-fips.us-gov-west-1.amazoncognito.com/login?' + 
  'client_id=32jpfd1u50vp8sos0gkn2u399g&response_type=token&scope=' +
  'openid+profile&redirect_uri=https://api.constellation.bwnuclear.ai/setcookie/'
)

const RenewAuthAlert = (props) => {
  const handleAlertClose = props.remove
  return (
    <Alert dismissible onClose={handleAlertClose}>
      Authorization is failing, sign in&nbsp;
      <Alert.Link href={url}>
        here
      </Alert.Link>
      .
    </Alert>
  )
}

export default RenewAuthAlert
