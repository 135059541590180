import webInfo from 'static/web_info.json'
import serviceInfo from 'static/service_info.json'


const loginUrl = (site) => `https://${process.env.GATSBY_COGNITO_DOMAIN}\
.auth-fips.${process.env.GATSBY_AWS_REGION}.amazoncognito.com/login\
?client_id=${process.env.GATSBY_COGNITO_CLIENT_ID}&response_type=token\
&scope=openid+profile&redirect_uri=https://${process.env.GATSBY_API_DOMAIN}\
/setcookie/${site}`

const alldataUrl = (site) => (
  `https://${process.env.GATSBY_API_DOMAIN}/alldata/${site}`
)

const profileUrl = `https://${process.env.GATSBY_API_DOMAIN}/profile`

const supportMailToUrl = (site) => {
  const email = webInfo.support_email
  const title = (
    (Object.keys(serviceInfo).includes(site))
      ? serviceInfo[site].title_name
      : 'BWNuclear'
  )
  return `mailto:support@bwnuclear.ai?subject=${title} User Support Request`
}

const downloadUrl = (site, type, key) => (
  `https://${process.env.GATSBY_API_DOMAIN}/download_url/${site}/${type}/${key}`
)


export {
  loginUrl,
  alldataUrl,
  profileUrl,
  supportMailToUrl,
  downloadUrl
}