import axios from 'axios'
import buildUploadParams from './buildUploadParams'
import { v4 as uuidv4 } from 'uuid'

const upload = async (site, type, file, params, dispatch) => {
  const jobId = uuidv4()
  dispatch({
    type: 'addAlert',
    toAdd: {
      type: 'warningText',
      text: 'Preparing for upload...',
      id: jobId
    }
  })
  let [urlParams, uploadParams] = buildUploadParams(type, params)
  if (site === 'tl' && type === 'historic') {
      urlParams.params.online = params.online
      uploadParams.headers['x-amz-meta-online'] = params.online
  }
  console.log('url parameters', urlParams)
  console.log('upload parameters', uploadParams)
  const url = (
    `https://${process.env.GATSBY_API_DOMAIN}/upload/`
    + site
    + '/'
    + type
  )
  const linkResult = await axios.get(url, urlParams)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data)
        console.log(error.response.data.toJSON())
        console.log(error.response.status)
        console.log(error.response.status.toJSON())
        console.log(error.response.headers)
        console.log(error.response.headers.toJSON())
      } else if (error.request) {
        console.log(error.request)
        console.log(error.request.toJSON())
      } else {
        console.log('Error! ', error.message)
      }
      console.log(error.config)
      console.log(error.config.toJSON())
    }
    )
  console.log('get presigned link result')
  console.log(linkResult)
  if (linkResult.data.url) {
    console.log(linkResult.data.url)
    const uploadUrl = linkResult.data.url
    uploadParams.onUploadProgress = (progressEvent) => {
      const totalLength = (
        progressEvent.lengthComputable
          ? progressEvent.total
          : (
              progressEvent.target.getResponseHeader('content-length') ||
              progressEvent.target
                .getResponseHeader('x-decompressed-content-length')
            )
      )
      const percent = (
        (totalLength !== null)
          ? Math.round((progressEvent.loaded * 100) / totalLength)
          : null
      )
      dispatch({
        type: 'updateAlert',
        id: jobId,
        updates: {
          progress: percent
        }
      })
    }
    dispatch({
      type: 'updateAlert',
      id: jobId,
      updates: {
        type: 'upload',
        text: '',
        progress: 0,
        filename: file.name
      }
    })
    const uploadResult = await axios.put(uploadUrl, file, uploadParams)
    console.log('upload file result', uploadResult)
    dispatch({
      type: 'updateAlert',
      id: jobId,
      updates: {
        type: 'text',
        text: 'Upload completed, please give a minute for the ' +
        'table to populate.'
      }
    })
  } else {
    dispatch({ type: 'addAlert', toAdd: { type: 'renew' } })
  }
}

export default upload
