import serviceInfo from 'static/service_info.json'


const supportTitle = (site) => {
  const service = (
    (Object.keys(serviceInfo).includes(site))
      ? serviceInfo[site].title_name
      : 'BWNuclear'
  )
  return `${service} Support`
}

const copyright = (year) => (
  `© ${year} Blue Wave AI Labs.  All Rights Reserved.`
)

export {
  supportTitle,
  copyright
}