import axios from 'axios'
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Hero from './hero'
import Header from './Header'
import Footer from './footer'
import AlertTray from './alertTray'
import upload from '../tools/aws/upload'
import { v4 as uuidv4 } from 'uuid'
import GlobalContextProvider from '../context/global_context_provider'
import appInfo from '../static/app_info'
import 'bootstrap/dist/css/bootstrap.css'
import { loginUrl, alldataUrl, profileUrl } from 'tools/aws/url'

const Layout = ({ children }) => {
  let subSite = ''
  if (typeof window !== 'undefined') {
    subSite = window.location.pathname.split('/')[1]
  }
  const siteTitle = (
    (subSite)
      ? {
          mco: 'mco',
          eigen: 'eigenvalue',
          profile: 'profile'
        }[subSite]
      : 'landing'
  )
  const [data, setData] = useState('')
  const [fetching, setFetch] = useState(false)
  const [personalView, setPersonalView] = useState(false)
  const [sort, setSort] = useState(false)
  const togglePersonalView = () => setPersonalView(!personalView)
  const [alerts, setAlerts] = useState([])
  const alertRef = useRef(alerts)
  alertRef.current = alerts
  const getSort = async () => {
    const params = { withCredentials: true }
    const getSortUrl = `https://${process.env.GATSBY_API_DOMAIN}/sort/get`
    const result = await axios.get(getSortUrl, params)
    if (result.data.result === 'success') {
      if (result.data.options.case_key === 'upload') {
        result.data.options.case_key = 'timestamp'
      }
      if (result.data.options.hdata_key === 'upload') {
        result.data.options.hdata_key = 'timestamp'
      }
      if (result.data.options.report_key === 'upload') {
        result.data.options.report_key = 'timestamp'
      }
      console.log('passed, setting options', result.data.options)
      setSort(result.data.options)
    }
    console.log(result)
  }
  useEffect(()=> {
    console.log('getting sort on page load')
    getSort()
  }, [])
  const addAlert = (newAlert) => {
    newAlert.id = uuidv4()
    setAlerts(oldArray => [...oldArray, newAlert])
    return newAlert.id
  }
  const updateAlert = (id, updates) => {
    const candidates = alertRef.current.filter(x => x.id === id)
    if (candidates !== 1) {
      return null
    }
    const itemToUpdate = candidates[0]
    Object.entries(updates).forEach((k, v) => {
      itemToUpdate[k] = v
    })
    setAlerts(
      alertRef
        .current
        .filter(x => x.id !== id)
        .concat(itemToUpdate)
    )
  }
  const removeAlert = (id) => {
    console.log('attempting remove of alert')
    console.log(alertRef.current)
    setAlerts(alertRef.current.filter(x => x.id !== id))
  }
  const startFetch = () => setFetch(true)
  const endFetch = () => setFetch(false)
  const fullName = a => a.familyName + ', ' + a.givenName
  const fetchData = async () => {
    if (fetching) {
      return false
    }
    startFetch()
    const params = { withCredentials: true }
    console.log(alldataUrl(subSite))
    const dataSite = (subSite === 'profile') ? 'tl' : subSite
    const graphqlData = await axios.get(alldataUrl(dataSite), params)
    console.log('data call', graphqlData)
    const profileData = await axios.get(profileUrl, params)
    console.log('profile call', profileData)
    if (!graphqlData.data.app_info || !profileData.data.result) {
      console.log('call didnt work, redirect')
      window.location.href = loginUrl(dataSite)
    } else {
      const owner = fullName(profileData.data.result)
      const devlist = appInfo.devlist
      if (!devlist.includes(owner)){
        graphqlData.data.historic = graphqlData.data.historic
          .filter(x => !devlist.includes(x.owner.S))
        graphqlData.data.predictions = graphqlData.data.predictions
          .filter(x => !devlist.includes(x.owner.S))
        graphqlData.data.reports = graphqlData.data.reports
          .filter(x => !devlist.includes(x.owner.S))
      }
      setData({
        hdata: graphqlData.data.historic,
        predictions: graphqlData.data.predictions,
        reports: graphqlData.data.reports,
        app_info: graphqlData.data.app_info,
        profile: profileData.data.result
      })
    }
    endFetch()
  }
  useEffect(() => {
    if (data === '' && fetching === false && subSite !== '') {
      console.log(subSite)
      console.log('fetching because no data and not fetching')
      fetchData()
    }
  }, [data])
  const uploadFile = async (type, file, params) => {
    addAlert('text', { text: 'Starting upload...' })
    await upload(type, file, params)
    addAlert('text', { text: 'Upload completed' })
  }
  const Child = React.Children.only(children)
  const propsToPass = {
    data: data,
    appInfo: appInfo,
    addAlert: addAlert,
    updateAlert: updateAlert,
    uploadFile: uploadFile,
    fetch: fetchData,
    personalView: personalView,
    togglePersonalView: togglePersonalView,
    sort: sort
  }
  const clone = React.cloneElement(Child, propsToPass)
  return (
      <GlobalContextProvider>
        <head>
          <title>{siteTitle}</title>
        </head>
        <Header />
        <Hero site={subSite} />
        <AlertTray
          alerts={alerts}
          remove={removeAlert}
        />
        <div>
          <main>
            {clone}
          </main>
          <Footer />
        </div>
      </GlobalContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
