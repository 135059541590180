import React, { useContext } from 'react'
import Container from 'react-bootstrap/Container'
import DownloadLinkAlert from './alerts/downloadLinkAlert'
import UploadProgressAlert from './alerts/uploadProgressAlert'
import DownloadProgressAlert from './alerts/downloadProgressAlert'
import RenewAuthAlert from './alerts/renewAuthAlert'
import TextAlert from './alerts/textAlert'
import LoadingAlert from './alerts/loadingAlert'
import WarningTextAlert from './alerts/warningTextAlert'
import {
  GlobalDispatchContext,
  GlobalStateContext
} from '../context/global_context_provider'

const AlertTray = (props) => {
  const state = React.useContext(GlobalStateContext)
  const dispatch = React.useContext(GlobalDispatchContext)
  const remove = (id) => {
    dispatch({ type: 'removeAlert', id: id })
  }
  const alerts = state.alerts.sort(x => x.id).map((item, index) => {
    switch (item.type) {
      case 'download':
        return (
          <DownloadLinkAlert
            key={item.id}
            url={item.url}
            remove={() => remove(item.id)}
          />
        )
      case 'upload':
        return (
          <UploadProgressAlert
            key={item.id}
            progress={item.progress}
            filename={item.filename}
            remove={() => item.remove()}
          />
        )
      case 'downloadProgress':
        return (
          <DownloadProgressAlert
            key={item.id}
            progress={item.progress}
            filename={
              ('filename' in item)
                ? item.filename
                : null
            }
          />
        )
      case 'text':
        return (
          <TextAlert
            key={item.id}
            text={item.text}
            remove={() => remove(item.id)}
          />
        )
      case 'warningText':
        return (
          <WarningTextAlert
            key={item.id}
            text={item.text}
            remove={() => remove(item.id)}
          />
        )
      case 'renew':
        return (
          <RenewAuthAlert
            key={item.id}
            remove={() => remove(item.id)}
          />
        )
      case 'loading':
        return (
          <LoadingAlert
            key={item.id}
            text={item.text}
          />
        )
      default:
        return null
    }
  })
  return (
    <Container className='fixed-bottom mb-1'>
      {alerts}
    </Container>
  )
}

export default AlertTray
