const appInfo = {
  devlist: [
    'Unterreiner, Justin',
    'Prall, Andy'
  ],
  stations: {
    clinton: {
      name: 'Clinton',
      units: {
        1: {
          limit: 0.1,
          mco_model: 'availible',
          eig_model: 'availible',
          operatingCycle: 21
        }
      },
    },
    dresden: {
      name: 'Dresden',
      units: {
        2: {
          limit: 0.1,
          mco_model: 'availible',
          eig_model_available: true,
          operatingCycle: 21
        },
        3: {
          limit: 0.1,
          mco_model: 'availible',
          eig_model_available: 'yes',
          operatingCycle: 21
        }
      }
    },
    fitzpatrick: {
      name: 'FitzPatrick',
      units: {
        1: {
          limit: 0.1,
          mco_model: 'availible',
          eig_model: 'availible',
          operatingCycle: 25
        }
      },
    },
    lasalle: {
      name: 'LaSalle',
      units: {
        1: {
          limit: 0.15,
          eig_model: 'availible',
          mco_model: 'availible',
          operatingCycle: 19
        },
        2: {
          limit: 0.15,
          eig_model: 'availible',
          mco_model: 'availible',
          operatingCycle: 19
        }
      }
    },
    limerick: {
      name: 'Limerick',
      units: {
        1: {
          limit: 0.5,
          eig_model: 'availible',
          mco_model_available: true,
          operatingCycle: 19
        },
        2: {
          limit: 0.5,
          eig_model: 'availible',
          mco_model_available: true,
          operatingCycle: 17
        }
      },
    },
    peach: {
      name: 'Peach Bottom',
      units: {
        2: {
          mco_model: 'availible',
          eig_model: 'availible',
          limit: 0.1,
          operatingCycle: 24
        },
        3: {
          mco_model: 'availible',
          eig_model: 'availible',
          limit: 0.1,
          operatingCycle: 24
        }
      }

    },
    nine: {
      name: 'Nine Mile Point',
      units: {
        1: {
          operatingCycle: 25,
          limit: 0.11,
          eig_model: 'availible',
          mco_model_available: true
        },
        2: {
          eig_model: 'availible',
          operatingCycle: 18,
          limit: 0.07,
          mco_model: 'availible'
        }
      }
    },
    quad: {
      name: 'Quad Cities',
      units: {
        1: {
          eig_model: 'availible',
          mco_model: 'availible',
          limit: 0.1,
          operatingCycle: 27
        },
        2: {
          eig_model: 'availible',
          mco_model: 'availible',
          limit: 0.1,
          operatingCycle: 26
        }
      }
    }
  }  
}

export default appInfo