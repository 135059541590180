import * as React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import emailLogo from '../images/paper-plane-white.png'
import { supportMailToUrl } from 'tools/aws/url'
import { supportTitle, copyright } from 'tools/text'
import textStrings from 'static/text.json'

import 'bootstrap/dist/css/bootstrap.css'

const Footer = () => {
  let email = '/'
  let label = 'BWnuclear.ai Support'
  if (typeof window !== 'undefined') {
    const subSite = window.location.pathname.split('/')[1]
    email = supportMailToUrl(subSite)
    label = supportTitle(subSite)
  }
  const year = new Date().getFullYear()
  return (
    <>
      <Card.Footer
        className='text-muted footer mt-auto py-3'
        style={{
          backgroundColor: '#f3f4f7',
          backgroundImage: 'linear-gradient(315deg, #f3f4f7 0%, #caccd1 74%)',
          backdropFilter: 'blur(4rem)'
        }}
      >
        <div className='container'>
          <p className='float-end mb-1'>
            <OverlayTrigger
              trigger={['hover', 'focus']} placement='top' overlay={
                <Popover id='popover-support'>
                  <Popover.Header
                    as='h3'
                    className='text-center mb-0 lead'
                    style={{ fontWeight: '100' }}
                  >
                    {label}
                  </Popover.Header>
                  <Popover.Body className='fw-light text-center'>
                    {textStrings.having_issues}
                  </Popover.Body>
                </Popover>
            }
            >
              <a href={email}>
                <img
                  src={emailLogo}
                  width='40'
                  height='auto'
                  alt='Contact'
                />
              </a>
            </OverlayTrigger>
          </p>
          <p className='mb-1' style={{ fontWeight: '100' }}>
            {copyright(year)}
          </p>
        </div>
      </Card.Footer>
    </>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string
}

Footer.defaultProps = {
  siteTitle: ''
}

export default Footer
